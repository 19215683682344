/*****************************/
//////////   COLORS  /////////
/*****************************/

$primary-color: rgb(61, 63, 74);
$secondary-color: #00a0e3;
$light-color: #f6f9fd;
$contrast-color: #fa5456;
$light-contrast-color: #fef0dd;
$grey: LightSlateGrey;
$white: #fff;
$black: #000;

// CUSTOMIZE PRIMARY COLOR FROM BOOTSTRAP
$blue: #00a0e3 !important;
$green: #50c878 !important;
$violet: #b97cfc !important;
$yellow: #ffae42 !important;
$marine: #2748a0 !important;
$darkred: #a70b22;
$lightgreen: #9adfb1 !important;

/*****************************/
//////////   FONTS  //////////
/*****************************/

$title-font: "NotoSansBold", sans-serif;
$text-font: "NotoSans", sans-serif;
$light-font: "NotoSansLight", sans-serif;

// RESPONSIVE FONT SIZE
@mixin fluid-type($font-min, $font-max) {
	font-size: #{$font-min};

	@media only screen and (min-width: 600px) {
		font-size: calc(#{$font-min} + #{($font-max - $font-min)} * (100vw - 600px) / (1200 - 600));
	}

	@media only screen and (min-width: 1200px) {
		font-size: #{$font-max};
	}
}

body {
	overflow-x: hidden;
	text-rendering: optimizeLegibility;
	@include fluid-type(0.85rem, 0.9rem);
	color: $primary-color;
	background-color: $light-color;
	height: 100%;
	margin: 0;
	font-family: $text-font;
	letter-spacing: 0;
}

p {
	font-family: $text-font;
	@include fluid-type(0.85rem, 0.9rem);
	color: $primary-color;
	white-space: pre-line;
	margin: 0 0 7px 0;
	line-height: 1.2rem;
	letter-spacing: 0.1px;
}

h1 {
	font-family: $title-font;
	color: $primary-color;
	@include fluid-type(1.3rem, 1.5rem);
	padding: 1rem 0;
	letter-spacing: 1px;
}

h2 {
	font-family: $title-font;
	color: $primary-color;
	@include fluid-type(1.07rem, 1.11rem);
	padding: 0.75rem 0;
	border-bottom: solid 1px lighten($color: $grey, $amount: 40);
}

h3 {
	font-family: $title-font;
	color: $primary-color;
	@include fluid-type(0.85rem, 0.87rem);
	text-transform: uppercase;
	padding: 0.75rem 0 0.5rem 0;
	letter-spacing: 0.1px;
	margin: 0;
}

h4 {
	font-family: $title-font;
	color: $primary-color;
	@include fluid-type(1rem, 1.1rem);
	padding: 1rem 0;
	letter-spacing: 0.3px;
	border-top: solid 1px lighten($color: $grey, $amount: 40);
}

h5 {
	font-family: $title-font;
	color: $primary-color;
	@include fluid-type(0.95rem, 0.98rem);
	letter-spacing: 0.2px;
	padding: 0.5rem 0;
}

ul li {
	display: list-item;
	list-style-type: disc;
}

ol li {
	display: list-item;
	list-style-type: decimal;
}

.regular-icon {
	height: 1.25rem;
	width: auto;
}

.title {
	font-family: $title-font;
	color: $primary-color;
	@include fluid-type(1.1rem, 1.15rem);
	padding: 0.8rem 0;
}

::placeholder {
	color: lighten($color: $grey, $amount: 25);
	@include fluid-type(0.8rem, 0.85rem);
	font-family: $light-font;
}

.rgpd {
	h4 {
		color: $primary-color;
		@include fluid-type(0.9rem, 0.95rem);
		padding: 0.5rem 0;
	}
}

.amd-goal {
	width: 33.2%;
	border-right: solid 1px $grey;
	margin: 0 0 0 1rem;

	.amd-goal-meta {
		height: 2.75rem;
		@include fluid-type(0.75rem, 0.8rem);
		color: $grey;
		letter-spacing: 0;
		font-family: $text-font;
	}

	p {
		@include fluid-type(0.82rem, 0.85rem);
		letter-spacing: 0;
		font-family: $title-font;
		margin: 1rem 0 0 0;
	}
	.meta {
		@include fluid-type(0.75rem, 0.8rem);
		font-family: $text-font;
		margin: 0.25rem 0 0 0;
	}
	h2 {
		font-family: $title-font;
		color: $primary-color;
		@include fluid-type(1.5rem, 1.6rem);
		padding: 0;
		margin: 0;
		line-height: auto;
		letter-spacing: 1px;
		border: none;
	}
}
.amd-goal:last-child {
	border-right: none;
}
.amd-goal:first-child {
	margin: 0;
}
.amd-goal-block {
	margin: 1rem 0 0.2rem 0;

	.amd-goal-dot {
		height: 0.8rem;
		width: 0.8rem;
		border-radius: 50%;
		display: inline-block;
		vertical-align: middle;
	}
	.amd-goal-text {
		@include fluid-type(0.8rem, 0.83rem);
		letter-spacing: 0;
		font-family: $title-font;
		margin: 0.1rem 0 0 0.2rem;
		display: inline-block;
	}
}
.gradient-card {
	border-radius: 7px;
	padding: 0.75rem;
	width: 100%;
	font-family: $text-font;
	@include fluid-type(0.8rem, 0.85rem);
	letter-spacing: 0.5px;
	position: relative;
	background-color: $white;
	color: $primary-color;
	margin: 0;
	border: solid 1px darken($color: $light-color, $amount: 15);
	a {
		color: $primary-color;
	}

	a:hover {
		color: $secondary-color;
		text-decoration: none;
	}

	.number {
		color: $primary-color;
		@include fluid-type(1rem, 1.1rem);
		font-family: $title-font;
		letter-spacing: 0.5px;
	}
}

.gradient-card-icon {
	position: absolute;
	color: darken($color: $light-color, $amount: 10);
	bottom: 5px;
	right: 5px;
	width: auto;
	height: 25%;
}

.gradient-title {
	font-family: $title-font;
	letter-spacing: 0.75px;
	@include fluid-type(0.97rem, 1rem);
	color: $primary-color;
	padding-bottom: 0.5rem;
	line-height: 1rem;
}

.manager-toast-card-alert {
	background-color: $white;
	border-radius: 8px;
	border: solid 1px $contrast-color;
}

.manager-toast-card {
	background-color: $white;
	border-radius: 8px;
	border: none;
}

.manager-toast-type {
	padding: 0.75rem;

	.toast-meta {
		@include fluid-type(0.7rem, 0.75rem);
		color: $grey;
		padding: 0;
		font-family: $text-font;
		margin: 0 0 0.2rem 0;
	}

	.title-toast {
		font-family: $title-font;
		margin: 0.85rem 0 0.25rem 0;
	}
	.title-toast:hover {
		color: $secondary-color;
	}
}
.toast-separator {
	border-top: solid 1px lighten($color: $grey, $amount: 20);
	width: 100%;
	margin: 0.6rem 0;
	padding: 0;
}

.big-icon {
	width: 100%;
	height: 1.5rem;
	padding: 0;
	margin: 0;
	cursor: pointer;
	&.light {
		color: darken($color: $light-color, $amount: 8);
	}
	&.red {
		color: $contrast-color;
	}
}

.big-icon:hover {
	&.light {
		color: $secondary-color;
	}
	&.red {
		color: $primary-color;
	}
}
.meta-counter {
	@include fluid-type(0.8rem, 0.85rem);
	text-transform: uppercase;
	font-family: $light-font;
	color: $grey;
	letter-spacing: 0;
}

.manager-icon-button {
	width: auto;
	height: 1.7rem;
}

.manager-big-button {
	width: 100%;
	border: solid 1px darken($color: $light-color, $amount: 15);
	border-radius: 8px;
	background-color: $white;
	color: $secondary-color;
	transition: 0.4s;
	padding: 0.5rem;
	margin: 1rem 0;
	h3 {
		letter-spacing: 0;
		font-weight: 400;
	}
}

.manager-big-button:hover {
	background: $primary-color;
	color: $white;
	h3 {
		color: $white;
	}
	p {
		color: $white;
	}
}

.manager-big-button:focus {
	outline: none;
}

.last-notifications-widget {
	width: 35%;
	max-width: 20rem;
	min-width: 16rem;
	margin: 0.5rem 0 0 1rem;
}

.grey {
	color: lighten($color: $grey, $amount: 30);
}

.grey-font {
	color: $grey;
}

.darkgrey {
	color: $grey;
}

.white {
	color: $white;
}

.yellow {
	color: $yellow;
}

.fake-link {
	cursor: pointer;
}

.text-link {
	color: $secondary-color;
	cursor: pointer;
}

.text-link:hover {
	text-decoration: underline;
}
.display-overflow {
	padding: 0.5rem;
	overflow: auto;
	max-height: 50vh;
}
.blue {
	color: $secondary-color;
}

.red {
	color: $contrast-color;
}

.green {
	color: $green;
}
.big-blue-check-icon {
	width: auto;
	height: 1.3rem;
	color: $secondary-color;
}
.big-grey-check-icon {
	width: auto;
	height: 1.3rem;
	color: $grey;
	cursor: pointer;
}
.big-grey-check-icon:hover {
	color: $secondary-color;
}
.big-check-icon {
	width: auto;
	height: 1.3rem;
	color: $green;
}
.big-check-icon:hover {
	color: $secondary-color;
}
.big-sync-icon {
	width: auto;
	height: 1.3rem;
	color: $grey;
}
.big-sync-icon:hover {
	color: $secondary-color;
}

.colored-tag {
	padding: 0.1rem 0.4rem 0.2rem 0.4rem;
	border-radius: 7px;
	@include fluid-type(0.76rem, 0.8rem);
	font-family: $title-font;
	color: $white !important;
	margin: 0;

	&.blue {
		border: solid 1px $secondary-color;
		background-color: $secondary-color;
	}
	&.red {
		border: solid 1px $contrast-color;
		background-color: $contrast-color;
	}
	&.green {
		border: solid 1px $green;
		background-color: $green;
	}
	&.yellow {
		border: solid 1px $yellow;
		background-color: $yellow;
	}
	&.purple {
		border: solid 1px $violet;
		background-color: $violet;
	}
	&.marine {
		border: solid 1px $marine;
		background-color: $marine;
	}
	&.darkred {
		border: solid 1px $darkred;
		background-color: $darkred;
	}
	&.grey {
		border: solid 1px $grey;
		background-color: $grey;
	}
}

.small-tag {
	padding: 0 0.2rem;
	margin: 0;
	border-radius: 7px;
	@include fluid-type(0.75rem, 0.77rem);
	border: none;
	letter-spacing: 0.3px;
	color: $white !important;
	font-family: $text-font;
	display: inline-block;

	&.blue {
		border: none;
		background-color: $secondary-color;
	}
	&.red {
		border: none;
		background-color: $contrast-color;
	}
	&.green {
		border: none;
		background-color: $green;
	}
	&.yellow {
		border: none;
		background-color: $yellow;
	}
	&.purple {
		border: none;
		background-color: $violet;
	}
	&.marine {
		border: none;
		background-color: $marine;
	}
	&.darkred {
		border: none;
		background-color: $darkred;
	}
	&.grey {
		border: none;
		background-color: $grey;
	}
}

.msg-tag {
	padding: 0.2rem 0.4rem;
	margin: 0 0.2rem 0 0;
	border-radius: 7px;
	@include fluid-type(0.75rem, 0.8rem);
	border: none;
	display: inline-block;
	color: $white !important;
	font-family: $title-font;
	letter-spacing: 0.1px;

	&.blue {
		background-color: $secondary-color;
	}
	&.red {
		background-color: $contrast-color;
	}
	&.green {
		background-color: $green;
	}
	&.yellow {
		background-color: $yellow;
	}
	&.purple {
		background-color: $violet;
	}
	&.marine {
		background-color: $marine;
	}
	&.darkred {
		background-color: $darkred;
	}
	&.grey {
		background-color: $grey;
	}
}

.category-outline-badge {
	padding: 0.1rem 0.4rem;
	margin: 0.15rem;
	border-radius: 7px;
	@include fluid-type(0.77rem, 0.8rem);
	font-family: $title-font;
	border: none;
	display: inline-block;
	background-color: $white;

	&.blue {
		border: solid 1px $secondary-color;
		color: $secondary-color;
	}
	&.red {
		border: solid 1px $contrast-color;
		color: $contrast-color;
	}
	&.green {
		border: solid 1px $green;
		color: $green;
	}
	&.yellow {
		border: solid 1px $yellow;
		color: $yellow;
	}
	&.purple {
		border: solid 1px $violet;
		color: $violet;
	}
	&.marine {
		border: solid 1px $marine;
		color: $marine;
	}
	&.darkred {
		border: solid 1px $darkred;
		color: $darkred;
	}
	&.grey {
		border: solid 1px $grey;
		color: $grey;
	}
}

.category-badge {
	padding: 0.1rem 0.4rem;
	margin: 0.15rem;
	color: $white !important;
	border-radius: 7px;
	@include fluid-type(0.77rem, 0.8rem);
	font-family: $title-font;
	border: none;
	display: inline-block;

	&.blue {
		border: solid 1px $secondary-color;
		background-color: $secondary-color;
	}
	&.red {
		border: solid 1px $contrast-color;
		background-color: $contrast-color;
	}
	&.green {
		border: solid 1px $green;
		background-color: $green;
	}
	&.yellow {
		border: solid 1px $yellow;
		background-color: $yellow;
	}
	&.purple {
		border: solid 1px $violet;
		background-color: $violet;
	}
	&.marine {
		border: solid 1px $marine;
		background-color: $marine;
	}
	&.darkred {
		border: solid 1px $darkred;
		background-color: $darkred;
	}
	&.grey {
		border: solid 1px $grey;
		background-color: $grey;
	}
}

.category-badge:hover {
	color: $white !important;

	&.blue {
		text-decoration: none !important;
		border: solid 1px darken($color: $secondary-color, $amount: 5);
		background-color: darken($color: $secondary-color, $amount: 5);
	}
	&.red {
		border: solid 1px $darkred;
		background-color: $darkred;
	}
	&.green {
		border: solid 1px $green;
		background-color: $green;
	}
	&.yellow {
		border: solid 1px $yellow;
		background-color: $yellow;
	}
	&.purple {
		border: solid 1px $violet;
		background-color: $violet;
	}
	&.marine {
		border: solid 1px $marine;
		background-color: $marine;
	}
	&.darkred {
		border: solid 1px $darkred;
		background-color: $darkred;
	}
}

.category-outline-badge:hover {
	color: $white !important;
	&.blue {
		border: solid 1px $secondary-color;
		background-color: $secondary-color;
	}
	&.red {
		border: solid 1px $contrast-color;
		background-color: $contrast-color;
	}
	&.green {
		border: solid 1px $green;
		background-color: $green;
	}
	&.yellow {
		border: solid 1px $yellow;
		background-color: $yellow;
	}
	&.purple {
		border: solid 1px $violet;
		background-color: $violet;
	}
	&.marine {
		border: solid 1px $marine;
		background-color: $marine;
	}
	&.darkred {
		border: solid 1px $darkred;
		background-color: $darkred;
	}
	&.grey {
		border: solid 1px $grey;
		background-color: $grey;
	}
}

.category-outline-badge:focus {
	outline: 0;
}

.category-badge:focus {
	outline: 0;
}

.recipient-badge {
	padding: 0.5rem 1rem;
	margin: 0.5rem 0;
	color: $primary-color;
	border-radius: 8px;
	@include fluid-type(0.75rem, 0.85rem);
	background-color: $white;
	font-family: $title-font;
	border: solid 1px lighten($color: $grey, $amount: 30);
	display: block;
}

.rules-list {
	padding: 0;
	margin: 0;
}

.light-list {
	list-style-type: none;
	border-top: solid 1px lighten($color: $grey, $amount: 45);
}

.add-icon {
	color: $white;
	width: 1rem;
	height: auto;
	cursor: pointer;
	margin-right: 0.5rem;
	vertical-align: middle;
}

.tooltip-container {
	position: relative;
}

.tooltip-box {
	width: 100%;
	min-width: 4.5rem;
	text-align: center;
	position: absolute;
	background: rgba(0, 0, 0, 0.7);
	color: #fff;
	padding: 2px;
	border-radius: 5px;
	top: 3rem;
	display: none;
	right: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 1;

	&:after {
		content: "";
		position: absolute;
		left: calc(50% - 2px);
		top: -4px;
		width: 0;
		height: 0;
		border-left: 4px solid transparent;
		border-right: 4px solid transparent;
		border-bottom: 4px solid rgba(0, 0, 0, 0.7);
	}
}

.tooltip-box.visible {
	display: block;
}

.form-label {
	font-family: $text-font;
	@include fluid-type(0.8rem, 0.85rem);
	color: $primary-color;
}

.header-details {
	background-color: $light-color;
	border-radius: 7px;
	margin: 1rem 0;
	padding: 0.75rem;
	font-family: $text-font;
	.cesure {
		overflow-wrap: anywhere;
	}
}

.diaper-statistics-light {
	border-radius: 7px;
	padding: 0.4rem 0.4rem 0 0.8rem;
	font-family: $text-font;
	height: 7.5rem;
	margin: 1rem 0;
	border: solid 1px lighten($color: $grey, $amount: 25);
	vertical-align: middle;
	background-color: $white;
	.meta {
		@include fluid-type(0.85rem, 0.9rem);
		color: $grey;
		font-family: $light-font;
		margin: 0.75rem 0;
	}
	.title {
		font-family: $title-font;
		@include fluid-type(0.85rem, 0.9rem);
		letter-spacing: 0.3px;
	}
}

.dotation-statistics {
	background-color: $secondary-color;
	padding: 0.5rem 0.75rem;
	border-radius: 7px;
	width: 100%;
	height: 100%;
	margin: 0;
	.title {
		font-size: 1.2rem;
		color: $white;
		font-family: $text-font;
		padding-top: 0.25rem;
	}
	.average {
		font-size: 1.4rem;
		color: $white;
		letter-spacing: 0.3px;
		font-family: $title-font;
	}
}

.diaper-statistics {
	background-color: $secondary-color;
	font-family: $text-font;
	border-radius: 7px;
	margin: 1rem 0;
	padding: 0.5rem 0.75rem;
	color: $white;
	position: relative;
	height: 7.5rem;
	.title {
		font-size: 1rem;
		color: $white;
		padding-top: 0.25rem;
		font-family: $title-font;
	}
	.saturation-statistic {
		font-size: 1.2rem;
		color: $white;
	}
	.saturation-icon {
		width: auto;
		height: 2rem;
		color: $light-color;
		position: absolute;
		bottom: 5px;
		right: 5px;
	}
}

.diaper-card {
	border-radius: 7px;
	margin: 0.75rem 0.75rem 0 0;
	padding: 0;
	display: block;
	color: $primary-color;
	width: 100%;
	min-width: 18rem;
	max-width: 19rem;
	border: solid 1px lighten($color: $grey, $amount: 40);
	position: relative;

	&.blue {
		background-color: darken($color: $light-color, $amount: 4);
	}
	&.grey {
		background-color: #ececec;
	}
	&.alert {
		background-color: lighten($color: #b97cfc, $amount: 22);
	}

	.diaper-alert-icon {
		height: 3.6rem;
		width: auto;
		color: $white;
		border: solid 3px $white;
		border-radius: 50%;
		padding: 0.5rem;
		margin: 0 1rem 0 0;
		z-index: 1;

		&.saturation {
			background-color: $violet;
		}
		&.check {
			background-color: $green;
		}
		&.dotation {
			background-color: $contrast-color;
		}
		&.unchanged {
			background-color: $blue;
		}
		&.other {
			background-color: lighten($color: #50c878, $amount: 10);
		}
	}
}

.diaper-info-block {
	padding: 0.75rem;

	.senior-name {
		line-height: normal;
		margin: 0.3rem 0 0 0;
		padding: 0;
		font-size: 1rem;
		font-family: $title-font;
	}

	.diaper-sat-info {
		margin: 1rem 0 0 0;
		padding: 0;
		height: auto;
		min-height: 11rem;
		p {
			margin: 0;
			font-size: 0.8rem;
		}
	}
}

.sensor-info-block {
	padding: 0.75rem;
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
}

.change-diaper-without-link {
	background-color: $white;
	margin: 0;
	padding: 0.25rem 0.75rem;
	width: 100%;
	border: solid 1px $white;
	height: auto;
	min-height: 4rem;
	position: absolute;
	left: 0;
	bottom: 2.75rem;
}

.change-diaper {
	cursor: pointer;
	background-color: $white;
	margin: 0;
	padding: 0.25rem 0.75rem;
	width: 100%;
	border: solid 1px $white;
	height: auto;
	min-height: 4rem;
	position: absolute;
	left: 0;
	bottom: 2.75rem;
}

.change-diaper:hover {
	border: solid 1px $secondary-color;
}

.change-diaper-without-sensor {
	background-color: $white;
	margin: 0;
	padding: 0.25rem 0.75rem;
	width: 100%;
	position: absolute;
	left: 0;
	bottom: 2.75rem;
	height: auto;
	min-height: 4rem;
}

.remove-sensor {
	cursor: pointer;
	font-family: $text-font;
	@include fluid-type(0.78rem, 0.8rem);
	color: $grey;
}
.remove-sensor:hover {
	color: $secondary-color;
	text-decoration: underline;
}

.diaper-button {
	color: $secondary-color;
	cursor: pointer;
}

.diaper-button:hover {
	text-decoration: underline;
}

.body-details {
	padding: 1rem;
	margin: 1.25rem 0;
	border-radius: 7px;
	border: solid 1px lighten($color: $grey, $amount: 50);
}

.error {
	color: $contrast-color;
	font-family: $text-font;
}

.required {
	color: $grey;
	font-family: $text-font;
}

.success {
	color: $secondary-color;
	font-family: $text-font;
}

.meta {
	@include fluid-type(0.75rem, 0.8rem);
	color: $grey;
	letter-spacing: 0.2px;
	font-family: $light-font;
	line-height: 1rem;
	&.red {
		color: $contrast-color;
	}
	&.green {
		color: $green;
	}
}

.meta-tabs {
	@include fluid-type(0.75rem, 0.8rem);
	color: $grey !important;
	letter-spacing: 0;
	font-family: $light-font;
	line-height: 1rem;
}

.small-meta {
	@include fluid-type(0.7rem, 0.75rem);
	color: $grey;
	padding: 0;
	margin-top: 0.25rem;
	font-family: $text-font;
}

.meta-link a {
	@include fluid-type(0.75rem, 0.8rem);
	color: $grey;
	padding: 0;
	margin: 0;
	font-family: $text-font;
	letter-spacing: 0.25px;
	cursor: pointer;
}

.bg-home-logo {
	background-color: $light-color;
	border-radius: 50%;
	width: 8.25rem;
	height: 8.25rem;
	padding: 1rem;
	position: relative;
	cursor: pointer;
}

.add-home-logo {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	text-align: center;
	@include fluid-type(0.8rem, 0.85rem);
	color: lighten($color: $primary-color, $amount: 25);
}

.add-new-logo {
	cursor: pointer;
	border: solid 1px lighten($color: $grey, $amount: 25);
	border-radius: 8px;
	padding: 0.2rem 0.5rem;
	margin-top: 1rem;
}

.add-new-logo:hover {
	border: solid 1px lighten($color: $grey, $amount: 25);
	background-color: $grey;
	color: $white;
}

.home-logo {
	width: 13rem;
	height: auto;
}

.img-timeline-mobile {
	max-width: 12.5rem;
	margin-top: 1rem;
}

.mood-emoticon {
	width: 1.75rem;
	height: auto;
	vertical-align: middle;
	margin-top: 0.5rem;
}

.avatar {
	width: 50px;
	height: auto;
}

.senior-avatar-activities {
	width: 160px;
	height: auto;
	margin: 1rem 0;
	border-radius: 50%;
}

.senior-avatar-notification {
	height: 100%;
	max-height: 2.9rem;
	width: auto;
	margin: 0 auto;
	border-radius: 50%;
}

.senior-title {
	font-family: $title-font;
	color: $primary-color;
	font-size: 1.25rem;
	border: none;
}

.senior-subtitle {
	font-family: $text-font;
	color: $primary-color;
	@include fluid-type(0.8rem, 0.85rem);
	letter-spacing: 0.5px;
	border: none;
}

.card {
	border-radius: 7px;
	margin: 1rem 0;
}

.card-header {
	padding: 2rem 1rem;
	background-color: $light-color;
}

.budget-card {
	padding: 0.35rem;
	border-radius: 4px;
	border: solid 1px lighten($color: $secondary-color, $amount: 40);
	text-align: center;
	width: 100%;
	display: block;
}

.badge-card {
	padding: 1rem 1rem 0rem 1rem;
	margin-bottom: 1rem;
	border-radius: 7px;
	border: solid 1px lighten($color: $grey, $amount: 40);
	cursor: pointer;
}

.badge-card:hover {
	border: solid 1px lighten($color: $grey, $amount: 40);
	background-color: $light-color;
}

.badge-card-selected {
	padding: 1rem 1rem 0rem 1rem;
	margin-bottom: 1rem;
	border-radius: 7px;
	background-color: $light-color;
	border: solid 1px $secondary-color;
	cursor: pointer;
}

.form-card {
	padding: 1rem 1rem 0.4rem 1rem;
	margin: 2rem 0;
	border-radius: 7px;
	border: solid 1px lighten($color: $grey, $amount: 40);
}

.form-card.pb-0 {
	padding: 1.25rem 1.25rem 0 1.25rem;
	margin-bottom: 2rem;
	border-radius: 7px;
	border: solid 1px lighten($color: $grey, $amount: 40);
}

.form-card-selected {
	padding: 1.5rem;
	margin-bottom: 2rem;
	border-radius: 7px;
	background-color: $light-color;
	border: solid 1px lighten($color: $grey, $amount: 40);
}

.blue-form-card {
	padding: 1rem;
	margin: 1rem 0;
	border-radius: 7px;
	background-color: $light-color;
}

.background-white {
	background-color: $white;
	padding: 0.75rem 0;
	border-bottom: solid 1px lighten($color: $grey, $amount: 30);
}

.button-text {
	color: $grey;
	cursor: pointer;
}

.small-submenu {
	margin-bottom: 1rem;
}

.number {
	font-family: $text-font;
	color: $secondary-color;
	font-size: 2rem;
}

.red {
	color: $contrast-color;
}

.center {
	text-align: center;
}

.toast-card {
	box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.15);
}

.login-container {
	width: 30rem;
	background-color: $white;
	padding: 3rem;
	box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
	margin: 5rem;

	.login-logo {
		width: 13rem;
		height: auto;
	}
	.amd-login-logo {
		width: 15rem;
		height: auto;
	}
}

.welcome-logo {
	width: 12rem;
	height: auto;
	margin: 2rem 0;
}

.small-list {
	list-style-type: none;
	margin: 0;
	padding: 0;
}

.msg-recipients-list {
	list-style-type: none;
	margin: 0;
	padding: 0;
	li {
		display: inline-block;
	}

	.msg-recipient-tag {
		padding: 0.1rem 0.2rem 0.2rem 0.2rem;
		border-radius: 7px;
		@include fluid-type(0.76rem, 0.8rem);
		font-family: $text-font;
		margin: 0 0.2rem 0 0;
		line-height: 2rem;
		background-color: $white;
		&.affiliate {
			border: solid 1px $grey;
			color: $marine;
		}
		&.principal {
			border: solid 1px $marine;
			color: $grey;
		}
	}
	.msg-recipient-icon {
		width: auto;
		height: 0.75rem;
	}
}

.light-button {
	border: none;
	cursor: pointer;
	color: $primary-color;
	background-color: $white;
}

.light-button:hover {
	border: none;
	cursor: pointer;
	color: $blue;
	background-color: $white;
}

.light-button:focus {
	outline: 0;
}

.groupName {
	line-height: 1.2rem;
}

.list-item {
	border: solid 1px lighten($color: $primary-color, $amount: 60);
	border-radius: 5px;
	margin: 0.75rem 0;
	padding: 1rem;
	background-color: $white;
	color: $primary-color;
	@include fluid-type(0.8rem, 0.85rem);
}

.header-list-items {
	border: solid 1px lighten($color: $primary-color, $amount: 60);
	border-left: solid 3px $secondary-color;
	border-radius: 5px;
	margin: 0.25rem 0;
	padding: 1rem;
	background-color: $white;
	color: $primary-color;
	font-family: $text-font;
	@include fluid-type(0.85rem, 0.9rem);
}

.diapers-list {
	border: solid 1px lighten($color: $primary-color, $amount: 60);
	border-left: solid 3px $secondary-color;
	border-radius: 5px;
	margin: 0.5rem 0;
	padding: 0.2rem 0.7rem;
	background-color: $white;
	color: $primary-color;
}

.referenced-diapers-list {
	border: solid 1px lighten($color: $primary-color, $amount: 60);
	border-radius: 5px;
	margin: 0.4rem 0.4rem 0.4rem 0;
	padding: 0.1rem 0.5rem;
	background-color: $white;
	width: 16.5rem;
	cursor: pointer;

	&.blue {
		border-left: solid 3px $secondary-color;
		p {
			color: $primary-color;
		}
	}
	&.grey {
		border-left: solid 3px $grey;
		p {
			color: $grey;
		}
	}
	.icon {
		width: 1.1rem;
		height: auto;
		color: $grey;
		display: inline-block;
	}
}

.referenced-diapers-list:hover {
	background-color: $light-color;

	&.blue {
		border-left: solid 3px $grey;
		p {
			color: $grey;
		}
	}
	&.grey {
		border-left: solid 3px $secondary-color;
		p {
			color: $primary-color;
		}
	}
	.icon {
		color: $secondary-color;
	}
}

.big-list-items {
	border: solid 1px lighten($color: $primary-color, $amount: 60);
	border-left: solid 3px $secondary-color;
	border-radius: 5px;
	margin: 0.75rem 0;
	padding: 1rem;
	background-color: $white;
	color: $primary-color;
	font-family: $text-font;
	@include fluid-type(0.8rem, 0.85rem);
}

.small-list-items {
	border: solid 1px lighten($color: $primary-color, $amount: 60);
	border-left: solid 3px $primary-color;
	border-radius: 5px;
	margin: 0.75rem 0;
	padding: 1rem;
	background-color: $white;
	color: $primary-color;
	font-family: $text-font;
	@include fluid-type(0.8rem, 0.85rem);
}

.small-list-items:hover {
	background-color: lighten($color: $light-color, $amount: 1);
}

.timeline-list-items {
	border: solid 1px lighten($color: $primary-color, $amount: 60);
	border-left: solid 3px $primary-color;
	border-radius: 5px;
	margin: 0.5rem 0 0 1.5rem;
	padding: 0.5rem;
	background-color: $white;
	@include fluid-type(0.8rem, 0.85rem);
}

.small-list-icon {
	width: 1rem;
	height: auto;
	color: $primary-color;
}

.success-msg {
	border: solid 1px lighten($color: $primary-color, $amount: 60);
	border-left: solid 3px $green;
	border-radius: 5px;
	padding: 0.5rem;
	background-color: $white;
	color: $primary-color;
	font-family: $text-font;
	@include fluid-type(0.8rem, 0.85rem);
	cursor: pointer;
	animation-duration: 1s;
	animation-name: slidein;

	@keyframes slidein {
		from {
			margin-left: 100%;
			width: 300%;
		}

		to {
			margin-left: 0%;
			width: 100%;
		}
	}
}

.error-msg {
	border: solid 1px lighten($color: $primary-color, $amount: 60);
	border-left: solid 3px $contrast-color;
	border-radius: 5px;
	padding: 0.5rem;
	background-color: $white;
	color: $primary-color;
	font-family: $text-font;
	@include fluid-type(0.8rem, 0.85rem);
	animation-duration: 1s;
	animation-name: slidein;

	@keyframes slidein {
		from {
			margin-left: 100%;
			width: 300%;
		}

		to {
			margin-left: 0%;
			width: 100%;
		}
	}
}

//// VIDEO JS
.vjs-default-skin .vjs-big-play-button {
	left: 50% !important;
	top: 50% !important;
	transform: translate(-50%, -50%);
	width: 80px !important;
	height: 50px !important;
}

/*****************************/
///////////   SENIOR  //////////
/*****************************/

.excel-badge {
	padding: 0.1rem 1rem;
	margin-top: 0.5rem;
	color: $green;
	border-radius: 10px;
	@include fluid-type(0.8rem, 0.85rem);
	background-color: $white;
	font-family: $text-font;
	border: solid 1px $green;
	cursor: pointer;
	transition-duration: 0.4s;
}

.excel-badge:hover {
	color: $white !important;
	background-color: $green;
	text-decoration: none;
}
.excel-badge:focus {
	outline: 0;
}

.rule-icon {
	width: 100%;
	max-width: 2.5rem;
	height: auto;
	cursor: pointer;
}

.rule-icon:hover {
	color: $secondary-color;
}

.video-container {
	display: inline-block;
	width: 100%;
	max-width: 38rem;
	margin: 0 auto;
}

.full-pict {
	max-width: 40rem;
	height: auto;
	margin: 1rem 0;
}

.stepper {
	position: relative;
	display: block;
	margin: 0 auto;
	height: 6rem;
	width: 100%;
}

.search-box {
	position: relative;
	width: 100%;
}

.search-toggle {
	position: absolute;
	right: 1rem;
	top: 0.4rem;
	cursor: pointer;
}

/*****************************/
///////////   MODAL  //////////
/*****************************/

.overlay {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background: rgba(0, 0, 0, 0.5);
	z-index: 10;
	text-align: left;
}

.dialog {
	background: white;
	border-radius: 8px;
	padding: 1.5rem;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 1;
	width: 85%;
	max-width: 37rem;
	height: auto;
	max-height: 75vh;
	overflow-y: auto;
}

.separator {
	border-top: solid 1px $grey;
	width: 50%;
	margin-bottom: 2rem;
}

.modal-title {
	font-family: $title-font;
	color: $primary-color;
	@include fluid-type(0.8rem, 0.85rem);
	margin: 0;
	padding-bottom: 1rem;
}

.close-modal {
	float: right;
	border: none;
	font-family: $text-font;
	@include fluid-type(0.9rem, 1rem);
	color: $primary-color;
	text-align: center;
	cursor: pointer;
}

.close-modal:hover {
	color: $secondary-color;
	border: none;
}

/// RGPD MODAL

.modal-rgpd {
	max-width: 30rem;
	max-height: 35rem;
	overflow-y: scroll;
	background: white;
	border-radius: 8px;
	padding: 1rem;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 1;
	p {
		text-align: left;
	}
	h4 {
		text-align: left;
		line-height: 1.25rem;
	}
}

.big-card {
	background-color: $white;
	padding: 1.25rem;
	border-radius: 7px;
	width: 100%;
	margin: 0;
}

.nav-link {
	margin: 0 0 0 0.5rem;
	cursor: pointer;
	letter-spacing: 0;
	&.active {
		font-weight: bold;
	}
}

.nav-link:hover {
	text-decoration: none;
	color: $secondary-color;
}
.light-table {
	display: flex;
	justify-content: space-between;
	padding-top: 0.5rem;
	margin-top: 0.3rem;
	border-top: solid 1px lighten($color: $grey, $amount: 30);
}

.space {
	margin-top: 1.2rem;
}

.small-form {
	padding-bottom: 2rem;
}

.document-icon {
	width: 18px;
	height: auto;
	margin: 0 auto;
	cursor: pointer;
	text-align: center;
	color: $primary-color;
	margin-right: 0.5rem;
}

.document-icon:hover {
	color: $secondary-color;
}

.family-icon {
	width: 24px;
	height: auto;
	cursor: pointer;
	text-align: center;
	color: $primary-color;
	margin-right: 0.5rem;
	margin-top: 0.6rem;
}

.family-icon:hover {
	color: $secondary-color;
}

.profil-icon {
	width: 1.75rem;
	height: auto;
	color: $primary-color;
	margin-right: 1rem;
}

.custom-select {
	margin: 0.2rem 0;
	@include fluid-type(0.8rem, 0.85rem);
}

.supervisor-name-list {
	border-left: solid 1px $grey;
	padding: 0 0.5rem;
	@include fluid-type(0.8rem, 0.85rem);
	font-family: $text-font;
	color: $primary-color;
}

.sort-icon {
	color: lighten($color: $grey, $amount: 42);
	cursor: pointer;
}

.check-icon {
	color: lighten($color: $grey, $amount: 30);
	cursor: pointer;
}

.check-icon:hover {
	color: $secondary-color;
}

.check-icon-selected {
	cursor: pointer;
}

.check-icon-selected:hover {
	color: $secondary-color;
}

.download-icon {
	width: 15px;
	height: auto;
	margin: 0 auto;
	text-align: center;
	color: $white;
	margin-right: 0.5rem;
}

.download-img {
	margin: 0.5rem;
	width: 2rem;
	height: auto;
	color: $secondary-color;
}

.family-list {
	list-style-type: none;
	margin: 1rem 0;
	padding: 0;
}

.room-icon {
	width: 25px;
	height: auto;
	color: $primary-color;
	margin: 0.5rem;
	vertical-align: middle;
}

.qr-code {
	width: 7.5rem;
	height: auto;
}

/*****************************/
//////   TOGGLE BUTTON  ///////
/*****************************/

.toggle-button {
	cursor: pointer;
	text-indent: -9999px;
	width: 35px;
	height: 20px;
	background: $primary-color;
	display: inline-block;
	border-radius: 50px;
	position: relative;
	margin: 0 0.2rem;
}

.toggle-button:after {
	content: "";
	position: absolute;
	top: 2px;
	left: 2px;
	width: 15px;
	height: 15px;
	background: $light-color;
	border-radius: 50%;
	transition: 0.2s;
}

.toggle-button-checked {
	cursor: pointer;
	text-indent: -9999px;
	width: 35px;
	height: 20px;
	background: $secondary-color;
	display: inline-block;
	border-radius: 50px;
	position: relative;
	margin: 0 0.2rem;
}

.toggle-button-checked:after {
	content: "";
	position: absolute;
	top: 2px;
	right: 2px;
	width: 15px;
	height: 15px;
	background: $light-color;
	border-radius: 50%;
	transition: 0.2s;
}

.toggle-meta {
	font-family: $text-font;
	font-size: 0.8rem;
	color: $grey;
	line-height: 1rem;
}

// Icon actif / pas actif (USER)

.badge-active {
	color: $secondary-color;
	width: 1rem;
	height: auto;
}

.badge-not-active {
	color: lighten($color: $grey, $amount: 30);
	width: 1rem;
	height: auto;
}

.table-hover tbody tr:hover {
	background: $light-color;
}

.table-hover tbody th {
	color: $primary-color;
	width: 55%;
	max-width: 1200px;
}

/*****************************/
///////////   FORM  //////////
/*****************************/

.label-checkbox {
	@include fluid-type(0.8rem, 0.85rem);
	font-family: $text-font;
	vertical-align: middle;
	line-height: 1.7rem;
	margin-left: 0.5rem;
}

.meta-label-checkbox {
	@include fluid-type(0.8rem, 0.85rem);
	font-family: $text-font;
	vertical-align: middle;
	margin-left: 0.3rem;
	color: $grey;
}

.input-badge input[type="radio"] {
	opacity: 0;
	position: fixed;
	width: 0;
}

.input-badge label {
	width: 100%;
	padding: 1rem;
	margin-bottom: 1rem;
	border-radius: 7px;
	border: solid 2px lighten($color: $grey, $amount: 30);
	cursor: pointer;
}

.input-badge input[type="radio"]:checked + label {
	background-color: $light-color;
	border: solid 2px $secondary-color;
}

// .input-badge input[type="radio"]:focus + label {
// 	border-color: darken($color: $light-color, $amount: 12);
// }

.input-badge label:hover {
	background-color: $light-color;
	border: solid 2px darken($color: $light-color, $amount: 12);
}

input[type="checkbox"] {
	vertical-align: middle;
	position: relative;
	width: 1rem;
	height: 1rem;
	color: $primary-color;
	border: 1px solid $grey;
	border-radius: 4px;
	appearance: none;
	outline: 0;
	cursor: pointer;
	&::before {
		position: absolute;
		content: "";
		display: block;
		bottom: 2px;
		left: 5px;
		width: 0.5rem;
		height: 1rem;
		border-style: solid;
		border-color: $white;
		border-width: 0 2px 2px 0;
		transform: rotate(45deg);
		opacity: 0;
	}
	&:checked {
		color: $white;
		border-color: $secondary-color;
		background: $secondary-color;
		&::before {
			opacity: 1;
		}
	}
}

input[type="checkbox"][disabled] {
	cursor: not-allowed;
	&:checked {
		color: $white;
		border-color: lighten($color: $grey, $amount: 12);
		background: lighten($color: $grey, $amount: 12);
		&::before {
			opacity: 1;
		}
	}
}

.app-version {
	margin-top: 6rem;
	text-align: center;
}

.input-password {
	position: relative;
}

.view-password {
	position: absolute;
	right: 0.5rem;
	top: 2rem;
	border: none;
	background: none;
	color: $primary-color;
	cursor: pointer;
}

.view-password:focus {
	outline: 0;
}

@media screen and (max-width: 640px) {
	.login-container {
		width: 24rem;

		.login-logo {
			width: 11rem;
			height: auto;
		}
	}
}

.daily-change-grid-container {
	display: grid;
	grid-template-columns: repeat(24, 1fr);
	grid-column-gap: 0px;
	grid-row-gap: 0px;
	position: relative;
	width: 100%;
}

.daily-change-hours {
	margin: 0.2rem;
	font-size: 0.7rem;
	font-family: $text-font;
	color: $grey;
	width: 100%;
	letter-spacing: 0;
}

.daily-change-icon {
	width: auto;
	height: 1.1rem;
	margin: 0;
}
